import React from "react"
import Image from "../Image"
import { Link } from "gatsby"

export default function SeeAndDo() {
  return (
    <div className="ezds-see-and-do">
      <div className="ezds-see-and-do-row">
        <div className="ezds-see-and-do-row-card">
          <span>{"THE BEACH OF ACHARAVI"}</span>
          <span>
            {
              "The beach of Acharavi is closest to the villa. It is only a 5 min drive from villa fedrita to this beach. It offers plenty of beach bars and a peacefull environment where you can relax and enjoy your stay."
            }
          </span>
          <Link
            style={{ textDecoration: "none" }}
            to="/contactUs"
            target="_blank"
          >
            <div className="ezds-see-and-do-row-button">{"Book your stay"}</div>
          </Link>
        </div>
        <div className="ezds-see-and-do-row-image">
          <Image
            fileName="seeAndDo-1.jpg"
            style={{ width: "500px", height: "400px" }}
            alt=""
          />
        </div>
      </div>
      <div className="ezds-see-and-do-row-2">
        <div className="ezds-see-and-do-row-image">
          <Image
            fileName="seeAndDo-2.jpg"
            style={{ width: "600px", height: "400px" }}
            alt=""
          />
        </div>
        <div className="ezds-see-and-do-row-card">
          <span>{"The Sunset taverna"}</span>
          <span>
            {
              "Are you looking for the perfect place to eat? This taverna provides an incredible view over the beach of Acharavi. As the name implies, you can admire the beautiful sunset while enjoying your diner in a very romantic setting."
            }
          </span>
          <Link
            style={{ textDecoration: "none" }}
            to="https://www.youtube.com/watch?v=luJPx8STttg"
            target="_blank"
          >
            <div className="ezds-see-and-do-row-button">{"View more"}</div>
          </Link>
        </div>
      </div>
      <div className="ezds-see-and-do-row-3">
        <div className="ezds-see-and-do-row-card">
          <span>{"Go diving or snorkling"}</span>
          <span>
            {
              "Corfu offers a wonderfull underwather life with visibility up to 30 meters (if the weather conditions allows this). In Acharavi village, you can visit a diving center, which is only 5 min from villa Fedrita."
            }
          </span>
          <Link
            style={{ textDecoration: "none" }}
            to="http://www.divecorfu.com/"
            target="_blank"
          >
            <div className="ezds-see-and-do-row-button">
              {"View their website"}
            </div>
          </Link>
        </div>
        <div className="ezds-see-and-do-row-image">
          <Image
            fileName="seeAndDo-3.jpg"
            style={{ width: "500px", height: "450px" }}
            alt=""
          />
        </div>
      </div>
      <div className="ezds-see-and-do-row-4">
        <div className="ezds-see-and-do-row-image">
          <Image
            fileName="seeAndDo-4.jpg"
            style={{ width: "400px", height: "550px" }}
            alt=""
          />
        </div>
        <div className="ezds-see-and-do-row-card">
          <span>{`Canal d'amour`}</span>
          <span>{`The origin of this name comes from an old myth. It says that every couple who swims through the 'gate' will get married and live an eternity of happiness. When swimming alone, will create luck in love. So there is no excuse not to visit this beautiful place. Canal d'amour is only a 15 min drive from villa Fedrita and located in Sidari.`}</span>
        </div>
      </div>
      <div className="ezds-see-and-do-row-5">
        <div className="ezds-see-and-do-row-card">
          <span>{`Hydropolis water park`}</span>
          <span>{`Only a 5 minutes drive from the villa, you can find the Hydropolis water park in Acharavi. It is a wonderful closeby trip enjoyable for the entire family.  The park is very close to the beach of Acharavi as well.`}</span>
          <Link
            style={{ textDecoration: "none" }}
            to="https://www.youtube.com/watch?v=Hni8SKKtrYE"
            target="_blank"
          >
            <div className="ezds-see-and-do-row-button">{"View more"}</div>
          </Link>
        </div>
        <div className="ezds-see-and-do-row-image">
          <Image
            fileName="seeAndDo-5.jpg"
            style={{ width: "550px", height: "300px" }}
            alt=""
          />
        </div>
      </div>
      <div className="ezds-see-and-do-row-6">
        <div className="ezds-see-and-do-row-card">
          <span>{`More information?​`}</span>
          <span>{`Upon your arrival in Acharavi, our host will welcome you in the villa and give you more tips about what to see and do on the island. If you have more questions, feel free to either contact us or our host. `}</span>
        </div>
      </div>
    </div>
  )
}
