import React from "react"
import Header from "../components/Header";
import Title from "../components/Title";
import SeeAndDoComponent from "../components/SeeAndDo";

export default function SeeAndDo() {
  return (
    <>
      <Header />
      <Title />
      <SeeAndDoComponent />
    </>
  )
}
